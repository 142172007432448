import Regulamin_Konkurs_Kalendarz_adwentowy from "./files/Regulamin_Konkurs_Kalendarz_adwentowy.docx";
import Regulamin_Konkurs_Wojna_cukierkowa from "./files/Regulamin_Konkurs_Wojna_cukierkowa.docx";
import Emi_i_Tajny_Klub_Superdziewczyn_Dookola_swiata_Wioska_Swietego_Mikolaja_Tom_5 from "./files/Emi_i_Tajny_Klub_Superdziewczyn_Dookola_swiata_Wioska_Swietego_Mikolaja_Tom_5.pdf";
import Operacja_szopka_fragment from "./files/Operacja_szopka_fragment.pdf";
import Kolorowanka_Mikolaj_612 from "./files/Kolorowanka_Mikolaj_612.pdf";
import Kolorowanka_Balwanek_712 from "./files/Kolorowanka_Balwanek_712.pdf";
import Instrukcja_pierniczki from "./files/Instrukcja_pierniczki.pdf";
import dziadek_do_orzechow_rysowanie_10_12 from "./files/dziadek_do_orzechow_rysowanie_10_12.pdf";
import znajdź_roznice_11_grudnia from "./files/znajdź_roznice_11_grudnia.pdf";
import The_Marvellers_Cudmistrzowie_Tom_1 from "./files/The_Marvellers_Cudmistrzowie_Tom_1.pdf";
import Regulamin_Konkurs_Wygraj_zestaw_swiatecznych_ksiazek from "./files/Regulamin_Konkurs_Wygraj_zestaw_swiatecznych_ksiazek.docx";
import Odszukaj_i_policz_14_12 from "./files/Odszukaj_i_policz_14_12.pdf";
import Mopsik_ktory_chcial_zostac_swiatecznym_jednorozcem from "./files/Mopsik_ktory_chcial_zostac_swiatecznym_jednorozcem.pdf";
import Renifer_szuka_drogi_16_12 from "./files/Renifer_szuka_drogi_16_12.pdf";
import Hooky_t_1_20_12 from "./files/Hooky_t_1_20_12.pdf";
import narysuj_niedzwiedza_1812 from "./files/narysuj_niedzwiedza_1812.pdf";
import ostatni_niedzwiedz_fragment from "./files/ostatni_niedzwiedz_fragment.pdf";
import kartka_z_prezentami_2012 from "./files/kartka_z_prezentami_2012.pdf";
import Lightfall_fragment from "./files/Lightfall_fragment.pdf";
import list_do_mikolaja from "./files/list_do_mikolaja.pdf";

export const arrayOfGifts = [
  // 1: operacja szopka; grafika + pdf
  <div className="gift-container gift-container__primary gift-container__download operacja_szopka">
    <a
      href={Operacja_szopka_fragment}
      target="_blank"
      rel="noreferrer"
      download={Operacja_szopka_fragment}
    >
      <p className="gift-anchor-content">fragment książki Operacja Szopka</p>
    </a>
  </div>,
  // 2: konkurs kalendarze adwentowe
  <div className="gift-container gift-container__display flex-col">
    <div className="gift-container__display-inner-box konkurs"></div>
    <a
      className="white-link"
      target="_blank"
      rel="noreferrer"
      href={Regulamin_Konkurs_Kalendarz_adwentowy}
      download={Regulamin_Konkurs_Kalendarz_adwentowy}
    >
      Pobierz regulamin konkursu
    </a>
  </div>,
  // 3. 5 nocy do gwiazdki piosenka
  <div className="gift-container">
    <iframe
      src="https://www.youtube.com/embed/tKl32IvElc4?si=prFo-QBC9d0p58q2"
      title="Piosenka 5 nocy do Gwiazdki"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  </div>,
  // 4. konkurs wojna cukierkowa
  <div className="gift-container gift-container__display flex-col">
    <div className="gift-container__display-inner-box konkurs_wojna"></div>
    <a
      className="white-link"
      target="_blank"
      rel="noreferrer"
      href={Regulamin_Konkurs_Wojna_cukierkowa}
      download={Regulamin_Konkurs_Wojna_cukierkowa}
    >
      Pobierz regulamin konkursu
    </a>
  </div>,
  // 5. emi fragment
  <div className="gift-container gift-container__primary gift-container__download emi_fragment">
    <a
      href={
        Emi_i_Tajny_Klub_Superdziewczyn_Dookola_swiata_Wioska_Swietego_Mikolaja_Tom_5
      }
      target="_blank"
      rel="noreferrer"
      download={
        Emi_i_Tajny_Klub_Superdziewczyn_Dookola_swiata_Wioska_Swietego_Mikolaja_Tom_5
      }
    >
      <p className="gift-anchor-content">
        fragment książki Emi i Tajny Klub Superdziewczyn Dookoła Świata Wioska
        Świętego Mikołaja
      </p>
    </a>
  </div>,
  // 6. kolorowanka chatka mikolaja
  <div className="gift-container gift-container__primary gift-container__download chatka-mikolaja">
    <a
      href={Kolorowanka_Mikolaj_612}
      target="_blank"
      rel="noreferrer"
      download={Kolorowanka_Mikolaj_612}
    >
      <p className="gift-anchor-content">kolorowanka Chatka Mikołaja</p>
    </a>
  </div>,
  // 7. kolorowanka balwanek
  <div className="gift-container gift-container__primary gift-container__download balwanek">
    <a
      href={Kolorowanka_Balwanek_712}
      target="_blank"
      rel="noreferrer"
      download={Kolorowanka_Balwanek_712}
    >
      <p className="gift-anchor-content">kolorowanka Balwanek</p>
    </a>
  </div>,
  // 8. kolorowanka pierniczki
  <div className="gift-container gift-container__primary gift-container__download pierniczki">
    <a
      href={Instrukcja_pierniczki}
      target="_blank"
      rel="noreferrer"
      download={Instrukcja_pierniczki}
    >
      <p className="gift-anchor-content">kolorowanka Pierniczki</p>
    </a>
  </div>,
  // 9. diy instagram
  <div className="gift-container gift-container__primary gift-container__download diy">
    <a
      href="https://www.instagram.com/wydawnictwo_wilga/"
      target="_blank"
      rel="noreferrer"
    >
      <p className="gift-anchor-content">Świąteczne DIY - Instagram</p>
    </a>
  </div>,
  // 10. dziadek do orzechow
  <div className="gift-container gift-container__primary gift-container__download nutcracker">
    <a
      href={dziadek_do_orzechow_rysowanie_10_12}
      target="_blank"
      rel="noreferrer"
      download={dziadek_do_orzechow_rysowanie_10_12}
    >
      <p className="gift-anchor-content">rysowanie - Dziadek do orzechów</p>
    </a>
  </div>,
  // 11. roznice
  <div className="gift-container gift-container__primary gift-container__download roznice">
    <a
      href={znajdź_roznice_11_grudnia}
      target="_blank"
      rel="noreferrer"
      download={znajdź_roznice_11_grudnia}
    >
      <p className="gift-anchor-content">Znajdź roznice</p>
    </a>
  </div>,
  // 12. marvellers
  <div className="gift-container gift-container__primary gift-container__download marvellers">
    <a
      href={The_Marvellers_Cudmistrzowie_Tom_1}
      target="_blank"
      rel="noreferrer"
      download={The_Marvellers_Cudmistrzowie_Tom_1}
    >
      <p className="gift-anchor-content">
        Pobierz fragment Marvellers - Cudmistrzowie
      </p>
    </a>
  </div>,
  // 13. konkurs_wygraj_zestaw regulamin
  <div className="gift-container gift-container__primary gift-container__download konkurs_wygraj_zestaw">
    <a
      href={Regulamin_Konkurs_Wygraj_zestaw_swiatecznych_ksiazek}
      target="_blank"
      rel="noreferrer"
      download={Regulamin_Konkurs_Wygraj_zestaw_swiatecznych_ksiazek}
    >
      <p className="gift-anchor-content">Pobierz regulamin konkursu</p>
    </a>
  </div>,
  // 14. odszukaj_policz
  <div className="gift-container gift-container__primary gift-container__download odszukaj_policz">
    <a
      href={Odszukaj_i_policz_14_12}
      target="_blank"
      rel="noreferrer"
      download={Odszukaj_i_policz_14_12}
    >
      <p className="gift-anchor-content">Pobierz grę odszukaj i policz</p>
    </a>
  </div>,
  // 15. mopsik
  <div className="gift-container gift-container__primary gift-container__download mopsik">
    <a
      href={Mopsik_ktory_chcial_zostac_swiatecznym_jednorozcem}
      target="_blank"
      rel="noreferrer"
      download={Mopsik_ktory_chcial_zostac_swiatecznym_jednorozcem}
    >
      <p className="gift-anchor-content">Pobierz fragment Mopsika</p>
    </a>
  </div>,
  // 16. renifer
  <div className="gift-container gift-container__primary gift-container__download renifer">
    <a
      href={Renifer_szuka_drogi_16_12}
      target="_blank"
      rel="noreferrer"
      download={Renifer_szuka_drogi_16_12}
    >
      <p className="gift-anchor-content">
        Pobierz obrazek renifera, który szuka drogi
      </p>
    </a>
  </div>,
  // 17 hooky
  <div className="gift-container gift-container__primary gift-container__download hooky">
    <a
      href={Hooky_t_1_20_12}
      target="_blank"
      rel="noreferrer"
      download={Hooky_t_1_20_12}
    >
      <p className="gift-anchor-content">Pobierz fragment ksiązki Hooky</p>
    </a>
  </div>,
  // 18 narysuj_niedzwiedzia
  <div className="gift-container gift-container__primary gift-container__download narysuj_niedzwiedzia">
    <a
      href={narysuj_niedzwiedza_1812}
      target="_blank"
      rel="noreferrer"
      download={narysuj_niedzwiedza_1812}
    >
      <p className="gift-anchor-content">
        Pobierz arkusz do rysowania niedźwiedzia
      </p>
    </a>
  </div>,
  // 19 ostatni_niedzwiedz
  <div className="gift-container gift-container__primary gift-container__download ostatni_niedzwiedz">
    <a
      href={ostatni_niedzwiedz_fragment}
      target="_blank"
      rel="noreferrer"
      download={ostatni_niedzwiedz_fragment}
    >
      <p className="gift-anchor-content">
        Pobierz fragment powieści Ostatni niedźwiedź
      </p>
    </a>
  </div>,
  // 20 karta_swiateczna
  <div className="gift-container gift-container__primary gift-container__download karta_swiateczna">
    <a
      href={kartka_z_prezentami_2012}
      target="_blank"
      rel="noreferrer"
      download={kartka_z_prezentami_2012}
    >
      <p className="gift-anchor-content">
        Pobierz instrukcję wykonania kartki świątecznej
      </p>
    </a>
  </div>,


  // 21 list_do_m
  <div className="gift-container gift-container__primary gift-container__download list_do_m">
    <a
      href={list_do_mikolaja}
      target="_blank"
      rel="noreferrer"
      download={list_do_mikolaja}
    >
      <p className="gift-anchor-content">Pobierz szablon listu do Mikołaja!</p>
    </a>
  </div>,
    // 22 lightfall
  <div className="gift-container gift-container__primary gift-container__download lightfall">
    <a
      href={Lightfall_fragment}
      target="_blank"
      rel="noreferrer"
      download={Lightfall_fragment}
    >
      <p className="gift-anchor-content">
        Pobierz fragment powieści Lightfall - Utracone światło
      </p>
    </a>
  </div>,
  // 23 video_zyczenia
  <div className="gift-container">
  <iframe
    src="https://www.youtube.com/embed/nUjUNN_-BLg?si=jLQJ_n5dvSjIsaVM"
    title="Życzenia świąteczne od naszych autorów"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  ></iframe>
</div>,
  // 24 video_wigilia
  <div className="gift-container">
  <iframe
    src="https://www.youtube.com/embed/_cewaqjFvBE?si=Cc1J4H2OUCqsb_sJ"
    title="Wesołych świąt zyczy wydawnictwo Wilga!"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  ></iframe>
</div>
];
