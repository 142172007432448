import Background from "./Components/Background/Background";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style/main.css";

function App() {
  return (
    <div className="app" id={"app"}>
      <Background />
    </div>
  );
}

export default App;
